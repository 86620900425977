<template>
  <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
    <v-card class="advertisement-form-container">
      <v-card-title>
        {{model.id ? 'Изменить карточку ККМ' : 'Добавить карточку ККМ'}}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <validation-provider v-slot="{errors}" name="series" rules="required|max:256">
              <v-text-field v-model="model.series"
                            required :error-messages="errors"
                            label="Фискал"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider v-slot="{errors}" name="uniq_id" rules="required|max:256">
              <v-text-field v-model="model.uniq_id"
                            required :error-messages="errors"
                            label="Серия"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider v-slot="{errors}" name="registration_date" rules="required">
              <v-menu v-model="dateMenu1" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="model.registration_date" label="Дата регистрации" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="model.registration_date" @input="dateMenu1 = false"></v-date-picker>
              </v-menu>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider v-slot="{errors}" name="subscription_date" rules="required">
              <v-menu v-model="dateMenu2" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="model.subscription_date" label="Оплачен до" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="model.subscription_date" @input="dateMenu2 = false"></v-date-picker>
              </v-menu>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider v-slot="{errors}" name="state" rules="required">
              <v-select v-model="model.state"
                        :items="states"
                        item-text="name" item-value="id"
                        required :error-messages="errors"
                        label="Статус"
              ></v-select>
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center pb-3 px-3">
        <v-btn @click="handleSubmit(save)"
               :loading="!!saving"
               :disabled="!!saving || invalid"
               rounded color="success"
        >Save
        </v-btn>
        <v-btn @click="cancel(reset)"
               :disabled="!!saving"
               :loading="!!saving"
               rounded text color="error"
        >Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'crm-form',

    data() {
      return {
        dateMenu1: false,
        dateMenu2: false,
      }
    },

    computed: {
      ...mapState('Crm', ['saving', 'model', 'states']),

      computedDate1() {
        return this.model.registration_date
      },

      computedDate2() {
        return this.model.subscription_date
      }
    },

    methods: {
      cancel(reset) {
        reset()
        this.$emit('crm:form:cancel')
      },

      save() {
        this.$store
          .dispatch('Crm/save')
          .then(() => {
            this.$emit('crm:form:saved')
          })
      },
    },
  }
</script>
