<template>
  <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
    <v-card class="payment-form-container">
      <v-card-title>Добавить платеж</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout row wrap class="mx-4">
          <v-flex xs12>
            <validation-provider v-slot="{errors}" name="tin" rules="required">
              <v-text-field v-model="model.tax_id"
                            required :error-messages="errors"
                            label="ИНН/ПИНФЛ клиента"
              ></v-text-field>
            </validation-provider>
          </v-flex>
          <v-flex xs12>
            <validation-provider v-slot="{errors}" name="doc_num" rules="required">
              <v-text-field v-model="model.doc_num"
                            :error-messages="errors"
                            label="Номер документа"
              ></v-text-field>
            </validation-provider>
          </v-flex>
          <v-flex xs12>
            <validation-provider v-slot="{errors}" name="description" rules="required">
              <v-text-field v-model="model.description"
                            :error-messages="errors"
                            label="Описание"
              ></v-text-field>
            </validation-provider>
          </v-flex>
          <v-flex xs12>
              <validation-provider v-slot="{errors}" name="amount" rules="required">
                  <v-text-field v-model="model.amount"
                                :error-messages="errors"
                                label="Сумма"
                  ></v-text-field>
              </validation-provider>
          </v-flex>
          <v-flex xs12>
            <validation-provider v-slot="{errors}" name="amount" rules="required">
              <v-menu ref="datemenu" v-model="datemenu" :close-on-content-click="false" :return-value.sync="model.payment_date"
                      transition="scale-transition" offset-y min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="pdate" label="Дата платежа" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="model.payment_date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="datemenu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.datemenu.save(model.payment_date)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </validation-provider>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions class="justify-center pb-3 px-3">
        <v-btn @click="handleSubmit(save)"
               :loading="!!saving"
               :disabled="!!saving || invalid"
               rounded color="success"
        >Save
        </v-btn>
        <v-btn @click="cancel(reset)"
               :disabled="!!saving"
               :loading="!!saving"
               rounded text color="error"
        >Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
  import {mapState} from 'vuex'
  import {Payment} from "../../store/payment";

  export default {
    name: 'customer-form',

    data() {
      return {
        datemenu: false,
      }
    },

    computed: {
      ...mapState('Payment', ['model', 'saving']),

      pdate() {
        return (new Date(this.model.payment_date)).toISOString().substr(0, 10)
      }
    },

    methods: {
      cancel(reset) {
        this.$emit('dialog:close')
      },

      save() {
        this.$store
          .dispatch('Payment/save')
          .finally(() => {
            this.$emit('dialog:close')
          })
      },
    },
  }
</script>
