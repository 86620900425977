var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',{staticClass:"advertisement-form-container"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.model.id ? 'Изменить карточку клиента' : 'Добавить карточку клиента')+" ")]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Название организации"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"tin","rules":_vm.model.pinfl ? '' : 'required|integer|max:14'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field-simplemask',{attrs:{"label":"ИНН клиента","options":{
                inputMask: '### ### ###',
                outputMask: '#########',
                empty: null,
                numeric: true,
              }},model:{value:(_vm.model.tin),callback:function ($$v) {_vm.$set(_vm.model, "tin", $$v)},expression:"model.tin"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"pinfl","rules":_vm.model.tin ? '' : 'required|integer|max:9'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field-simplemask',{attrs:{"label":"ПИНФЛ клиента","options":{
                inputMask: '# ###### ##### ##',
                outputMask: '##############',
                empty: null,
                numeric: true,
              }},model:{value:(_vm.model.pinfl),callback:function ($$v) {_vm.$set(_vm.model, "pinfl", $$v)},expression:"model.pinfl"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"registered_at","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Дата регистрации","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.computedDate),callback:function ($$v) {_vm.computedDate=$$v},expression:"computedDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.model.registered_at),callback:function ($$v) {_vm.$set(_vm.model, "registered_at", $$v)},expression:"model.registered_at"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.regions,"item-value":"id","item-text":"name","required":"","error-messages":errors,"label":"Область"},model:{value:(_vm.model.region_id),callback:function ($$v) {_vm.$set(_vm.model, "region_id", $$v)},expression:"model.region_id"}})]}}],null,true)})],1),(_vm.model.region_id)?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.areas,"item-text":"name","item-value":"id","required":"","error-messages":errors,"label":"Район"},model:{value:(_vm.model.area_id),callback:function ($$v) {_vm.$set(_vm.model, "area_id", $$v)},expression:"model.area_id"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Адрес"},model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"pinfl","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field-simplemask',{attrs:{"label":"Телефон","options":{
                inputMask: '+### (##) ###-##-##',
                outputMask: '############',
                empty: null,
                numeric: true,
              }},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"balance","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"mask":_vm.balanceMask,"error-messages":errors,"label":"Баланс"},model:{value:(_vm.model.balance),callback:function ($$v) {_vm.$set(_vm.model, "balance", $$v)},expression:"model.balance"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.statuses,"item-text":"name","item-value":"id","required":"","error-messages":errors,"label":"Статус"},model:{value:(_vm.model.state),callback:function ($$v) {_vm.$set(_vm.model, "state", $$v)},expression:"model.state"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center pb-3 px-3"},[_c('v-btn',{attrs:{"loading":!!_vm.saving,"disabled":!!_vm.saving || invalid,"rounded":"","color":"success"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_vm._v("Сохранить ")]),_c('v-btn',{attrs:{"disabled":!!_vm.saving,"loading":!!_vm.saving,"rounded":"","text":"","color":"error"},on:{"click":function($event){return _vm.cancel(reset)}}},[_vm._v("Отмена ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }