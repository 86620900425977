<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <span style="vertical-align:middle;" class="text-h5">Список клиентов</span>
        <v-dialog v-model="importerDialog" width="500">
          <template #activator="{on, attrs}">
            <v-btn class="ml-3" style="vertical-align:middle;" rounded color="primary"
                   v-bind="attrs" v-on="on" small
            >
              <v-icon>mdi-upload</v-icon>
              Импорт
            </v-btn>
          </template>
          <uploader @dialog:close="closeImport" :loading="importing" store-ns="Customer/import"></uploader>
        </v-dialog>
        <v-btn class="ml-3" style="vertical-align:middle;" rounded color="success"
               :disabled="saving" small
               @click="showCustomerFormDialog">
          <v-icon>mdi-plus</v-icon>
          добавить
        </v-btn>
        <v-dialog v-model="customerFormDialog" width="500" persistent>
          <customer-form @customer:form:saved="closeCustomerForm"
                         @customer:form:cancel="closeCustomerForm"
          ></customer-form>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="3">
        <v-text-field v-model="filter.name"
                      placeholder="Название организации"
                      prepend-inner-icon="mdi-account-search"
                      clearable
                      @keyup.enter="fetch(filter)"
                      @click:clear="clearName"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field v-model="filter.tin"
                      placeholder="ИНН или ПИНФЛ клиента"
                      prepend-inner-icon="mdi-account-search"
                      clearable
                      @keyup.enter="fetch(filter)"
                      @click:clear="clearTin"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="filter.balance"
          :items="[{id: '', title: 'все'}, {id: '-1', title: 'должники'}, {id: '1', title: 'архивные'}]"
          item-text="title"
          item-value="id"
          label="Баланс"
          clearable
          @click:clear="clearBalance"
          @input="fetch(filter)"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="filter.crm_state"
          :items="[{id: 'pending', title: 'ожидает включения'}, {id: 'active', title: 'активный'},{id: 'inactive', title: 'заблокирован'}]"
          item-text="title"
          item-value="id"
          label="Статус терминала"
          clearable
          @click:clear="clearState"
          @input="fetch(filter)"
        ></v-select>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="customers"
      :loading="!!fetching"
      multi-sort
      :sort-by="filter.sortColumns"
      :sort-desc="filter.sortOrders"
      disable-items-per-page hide-default-footer
      :options.sync="options"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{10 * (filter.page - 1) + index + 1}}</td>
          <td>{{item.name}}</td>
          <td>{{item.tax_id}}</td>
          <td><a :href="'tel:+' + item.phone">{{item.phone|phone}}</a></td>
          <td>{{(item.plan || {name:'not set'}).name}}</td>
          <td>{{item.balance | money}}</td>
          <td class="text-center">
            {{item.active_crms + item.pending_crms + item.inactive_crms}}
          </td>
          <td class="text-center">
            <v-icon small v-for="i in item.pending_crms" :key="i" color="yellow">
              mdi-circle
            </v-icon>
            <v-icon small v-for="i in item.active_crms" color="success">
              mdi-circle
            </v-icon>
            <v-icon small v-for="(i, index) in item.inactive_crms" :key="index" color="white--text red darken-3">
              mdi-circle
            </v-icon>
          </td>
          <td class="text-right">
            <v-btn color="success" text @click="showDetails(item)">
              <v-icon left small>mdi-eye</v-icon>
              Показать
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination v-model="page" :total-visible="10" :length="totalPages"></v-pagination>
    </div>
    <v-dialog v-model="detailDialog" width="60vw">
      <customer-details @dialog:close="closeDetails"></customer-details>
    </v-dialog>
  </v-container>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'

  const {mapState, mapActions} = createNamespacedHelpers('Customer')

  import uploader from '@/components/uploader'
  import customerDetails from '@/modules/customers/details'
  import customerForm from '@/modules/customers/form'

  export default {
    name: 'customer-list',

    data() {
      return {
        importerDialog: false,
        customerFormDialog: false,
        detailDialog: false,
        showForm: false,
        filter: {
          page: 1,
          search: null,
          sortBy: [],
          sortDesc: [],
          crm_state: null,
          tin: '',
          name: '',
        },
        options: {
          sortBy: [],
          sortDesc: [],
        },
      }
    },

    watch: {
      'options.sortBy': {
        handler(newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.filter.sortBy = newVal
            this.filter.page = 1
            this.fetch(this.filter)
          }
        },
        deep: true,
      },
      'options.sortDesc': {
        handler(newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.filter.sortDesc = newVal
            this.filter.page = 1
            this.fetch(this.filter)
          }
        },
        deep: true,
      },
    },

    created() {
      this.filter = {...this.filter, ...this.$route.query}
      this.fetch(this.filter)
    },

    computed: {
      ...mapState([
        'fetching',
        'importing',
        'data',
        'saving',
      ]),

      headers() {
        return [
          {text: '#', align: 'center', width: '100'},
          {text: 'Название', value: 'name',},
          {text: 'ИНН, ПИНФЛ', value: 'tin',},
          {text: 'Телефон', sortable: false},
          {text: 'Тариф', sortable: false},
          {text: 'Баланс', value: 'balance'},
          {text: 'ККМы', align: 'center', value: 'crms_count',},
          {text: 'Статусы ККМ', align: 'center', value: 'crms_count',},
          {text: 'Действия', align: 'right', sortable: false},
        ]
      },

      customers() {
        return this.data?.data || []
      },

      totalPages() {
        return (this.data || {last_page: 0}).last_page
      },

      page: {
        get() {
          return this.filter.page
        },

        set(val) {
          this.filter.page = val
          this.fetch(this.filter)
        }
      }
    },

    methods: {
      ...mapActions(['fetch', 'show']),

      clearTin() {
        this.filter.tin = null
        this.filter.page = 1
        this.fetch(this.filter)
      },

      clearName() {
        this.filter.name = ''
        this.filter.page = 1
        this.fetch(this.filter)
      },

      clearBalance() {
        this.filter.balance = null
        this.filter.page = 1
        this.fetch(this.filter)
      },

      clearState() {
        this.filter.crm_state = null
        this.filter.page = 1
        this.fetch(this.filter)
      },

      showDetails(item) {
        this.detailDialog = true
        this.show(item.id)
      },

      closeDetails() {
        this.detailDialog = false
        this.fetch(this.filter)
      },

      closeImport() {
        this.importerDialog = false
        this.fetch(this.filter)
      },

      showCustomerFormDialog(item) {
        this.$store.commit('Customer/setModel', item || {})
        this.customerFormDialog = true
      },

      closeCustomerForm() {
        this.$store.commit('Customer/setModel', {})
        this.customerFormDialog = false
        this.fetch(this.filter)
      },
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Customers list')
    },

    components: {
      uploader,
      customerDetails,
      customerForm,
    }
  }
</script>
