import Vue from 'vue'
Vue.config.productionTip = false

import Http from '@/router/http'
Vue.use(Http)

import {filterInstaller} from './filters'
Vue.use(filterInstaller)

import vuetify from '@/plugins/vuetify'
import '@/plugins/mask'


import store from '@/store'
import router from '@/router'

import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {min_value, integer, max, required, excluded} from 'vee-validate/dist/rules'

extend('integer', integer)
extend('max', max)
extend('required', required)
extend('min_value', min_value)
extend('excluded', excluded)
extend('uzphone', val => {
  return val.toString().startsWith('998') && val.toString().length === 12
})
extend('decimal', val => {
  return !isNaN(Number(val))
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)


import App from './App.vue'
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
