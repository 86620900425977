import Vue from 'vue'
import ut from '@/utils'
import {Resource} from 'vue-resource';

import router from '@/router/index'

const AuthResource = Resource('users{/id}', {}, {
  login: {
    method: 'POST',
    url: 'auth/login'
  },
  logout: {
    method: 'POST',
    url: 'auth/logout'
  },
})

export const AuthForm = {
  email: '',
  password: '',
}

export const User = {
  id: null,
  name: '',
  email: '',
  organization_id: null,
  organization: {},
}

const CsrfResource = Resource('auth/csrf-cookie')

export default {
  namespaced: true,

  state: {
    user: ut.deepClone(User),
    authing: false,
    loading: false,
    loadingCsrf: false,
  },

  mutations: {
    setUser(state, user = {}) {
      state.user = {...ut.deepClone(User), ...user}
    },
  },

  actions: {
    logout({state, commit}) {
      if (!state.authing) {
        state.authing = AuthResource
          .logout()
          .finally(() => {
            commit('setUser', ut.deepClone(User))
            state.authing = false
          })
      }

      return state.authing
    },

    login({state, commit}, data) {
      if (!state.authing) {
        state.authing = AuthResource
          .login(ut.objFilter(data))
          .then(response => {
            commit('setUser', response.body)
            Vue.$eventBus.$emit('auth:success')
          })
          .catch((response) => {
            Vue.$eventBus.$emit('notification:error', response.body.message)
          })
          .finally(() => state.authing = false)
      }

      return state.authing
    },

    load({state, commit, dispatch}) {
      if (!state.loading) {
        state.loading = AuthResource
          .query()
          .then(response => commit('setUser', response.body))
          .catch(() => {
            dispatch('logout')
            if (router.currentRoute.name !== 'login') {
              router.push({name: 'login'})
            }
          })
          .finally(() => state.loading = false)
      }

      return state.loading
    },

    update({state, commit}, data) {
      if (!state.loading) {
        state.loading = AuthResource
                          .update({id: data.id}, ut.objFilter(data))
                          .then(response => commit('setUser', response.body))
                          .finally(() => state.loading = false)
      }

      return state.loading
    },

    loadCsrf({state}) {
      if (!state.loadingCsrf) {
        state.loadingCsrf = CsrfResource
                              .get()
                              .finally(() => state.loadingCsrf = false)
      }

      return state.loadingCsrf
    }
  }
}
