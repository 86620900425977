<template>
  <div>
    <v-subheader class="px-0">
      ККМы
      <v-btn small class="ml-3" style="vertical-align:middle;" rounded color="success"
             @click="showCrmFormDialog">
        <v-icon small>mdi-plus</v-icon> добавить
      </v-btn>
      <v-dialog v-model="crmFormDialog" width="500" persistent>
        <crm-form v-if="crmFormDialog" @crm:form:saved="closeCrmForm"
                  @crm:form:cancel="closeCrmForm"
        ></crm-form>
      </v-dialog>
    </v-subheader>
    <v-data-table
      :headers="headers"
      :items="items"
      disable-sort
      disable-items-per-page hide-default-footer
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{index + 1}}</td>
          <td>
            <v-icon left x-small :color="getColor(item.id)">mdi-circle</v-icon>
            {{item.series}}
          </td>
          <td>{{item.uniq_id}}</td>
          <td><v-chip small class="white--text" :color="item.state === 'active' ? 'success' : (item.state === 'pending' ? 'warning' : 'red darken-3')">{{item.state}}</v-chip></td>
            <td class="text-center">
              {{item.registration_date|date}}
              <v-btn small icon color="primary" text @click="showChangeRegistrationForm(item)">
                <v-icon small color="error">mdi-pen</v-icon>
              </v-btn>

              <v-dialog v-model="showCrmDateForm" width="500" persistent>
                <crm-date-form @crm:date-form:saved="closeCrmDateForm"
                               @crm:date-form:cancel="closeCrmDateForm"
                ></crm-date-form>
              </v-dialog>
            </td>
            <td class="text-center">
              <v-icon small v-if="isDue(item.subscription_date)">mdi-clock</v-icon>
              <span v-else>{{item.subscription_date|date}}</span>
            </td>
            <td class="text-right">
              <v-btn class="mr-2" depressed color="success" small v-if="item.state === 'pending'" @click="confirmActivation(item)"><v-icon left small>mdi-eye</v-icon> Потвердить</v-btn>
              <v-btn depressed color="error" small @click="showRemoveDialog(item)"><v-icon left small>mdi-close</v-icon> Удалить</v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

    <v-dialog v-model="removeDialog" width="500">
      <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
        <v-card class="crm-remove-container">
          <v-card-title>Удание ККМ</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-layout row wrap class="mx-4">
              <v-flex xs12>
                <div>Фискал: {{removingCrm.series}}</div>
                <div>Серия: {{removingCrm.uniq_id}}</div>
              </v-flex>
              <v-flex xs12>
                <validation-provider v-slot="{errors}" name="pass" rules="required">
                  <v-text-field v-model="removePass"
                                required :error-messages="errors"
                                label="Введите пароль для удаления"
                  ></v-text-field>
                </validation-provider>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="justify-center pb-3 px-3">
            <v-btn @click="removeCrm"
                   :loading="!!removing"
                   :disabled="invalid"
                   rounded color="success"
            >Подтвердить
            </v-btn>
            <v-btn @click="cancelDeletion"
                   :loading="!!removing"
                   rounded text color="error"
            >Отмена</v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
  </div>

</template>

<script>
  import crmForm from '@/modules/crms/form'
  import crmDateForm from '@/modules/crms/date-form'
  import moment from 'moment'

  export default {
    name: 'crms-list',

    props: {
      customerId: {
        // type: Number,
        required: true,
      },

      items: {
        type: Array,
        required: true,
      }
    },

    data() {
      return {
        removePass: '',
        removeDialog: false,
        removingCrm: {},
        removing: false,
        headers: [
          {text: '#', align: 'center', width: '30'},
          {text: 'Фискал'},
          {text: 'Серия'},
          {text: 'Статус'},
          {text: 'Дата регистрации'},
          {text: 'Дата след платежа'},
          {text: 'Действия', align: 'right'},
        ],
        crmFormDialog: false,

        showCrmDateForm: false,
      }
    },

    methods: {
      isDue(date) {
        return moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
      },

      confirmActivation(crm) {
        this.$store.dispatch('Crm/confirmActivation', crm.id)
        .then(() => {
          this.$emit('crm:updated')
          this.$store.dispatch('Customer/show', crm.customer_id)
        })
      },

      getColor(crmId) {
        let crmIds = this.items.map(i => i.id)
        let colors = ['green darken-3', 'red darken-3', 'yellow darken-2', 'blue darken-2', 'indigo darken-2']
        return colors[crmIds.indexOf(crmId) % colors.length]
      },

      cancelDeletion() {
        this.removing = false
        this.removeDialog = false
      },

      removeCrm() {
        if (!this.removing) {
          this.removing = true
          this.$store.dispatch('Crm/remove', {id: this.removingCrm.id, pass: this.removePass})
            .then(() => {
              this.$emit('crm:updated')
            })
            .finally(() => {
              this.$store.dispatch('Customer/show', this.removingCrm.customer_id)
              this.removing = false
              this.removeDialog = false
              this.removePass = ''
              this.$emit('crm:list:update')
            })
        }
      },

      showRemoveDialog(crm) {
        this.removingCrm = crm
        this.removeDialog = true
      },

      showCrmFormDialog(item) {
        this.$store.commit('Crm/setModel', item?.customer_id ? item : {customer_id: this.customerId})
        this.crmFormDialog = true
      },

      closeCrmForm() {
        this.$store.commit('Crm/setModel', {})
        this.crmFormDialog = false
        this.$emit('crm:list:update')
      },

      showChangeRegistrationForm(model) {
        this.$store.commit('Crm/setModel', model)
        this.showCrmDateForm = true
      },

      closeCrmDateForm() {
        this.showCrmDateForm = false
        this.$emit('crm:list:update')
        this.$store.commit('Crm/setModel', {})
      },
    },
    components: {
      crmForm,
      crmDateForm,
    }
  }
</script>
