import Vue from 'vue'
import Vuex from 'vuex'

import Auth from './auth'
import Customer from './customer'
import Payment from './payment'
import Crm from './crm'
import {Resource} from "vue-resource";


const RegionResource = new Resource('regions{/id}')
const AreaResource = new Resource('areas{/id}')

Vue.use(Vuex)
Vue.prototype.$eventBus = Vue.$eventBus = new Vue

const store = new Vuex.Store({
  modules: {
    Auth,
    Customer,
    Payment,
    Crm,
  },

  state: {
    inited: false,
    miniDrawer: true,
    regions: [],
    areas: [],

    statuses: [
      {name: 'Актив', id: 'active'},
      {name: 'Неактив', id: 'inactive'},
      {name: 'В ожидании', id: 'pending'},
    ],
  },

  mutations: {
    inited(state) {
      state.inited = true
    },

    resizeDrawer(state) {
      state.miniDrawer = !state.miniDrawer
    },
  },

  actions: {
    load({state, commit, dispatch}) {
      Promise.all([
        dispatch('Auth/load'),
        dispatch('loadRegions'),
        dispatch('loadAreas'),
      ])
      .finally(() => {
        commit('inited')
      })
    },

    loadRegions({state}) {
      return RegionResource.get()
              .then((response) => {
                state.regions = response.data
              })
    },

    loadAreas({state}) {
      return AreaResource.get()
              .then((response) => {
                  state.areas = response.data
              })
    },
  }
})

export default store
