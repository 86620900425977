const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

const objFilter = (originalObj) => {
  let obj = deepClone(originalObj)
  for (let field in obj) {
    if (!obj[field]) {
      delete obj[field]
    }
  }
  return obj
}

const noop = () => {}

export default {
  deepClone,
  objFilter,
  noop
}
