var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',{staticClass:"advertisement-form-container"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.model.id ? 'Изменить карточку клиента' : 'Добавить карточку клиента')+" ")]),_c('v-divider'),_c('v-card-text',[_c('p',[_vm._v("Клиент: "+_vm._s(_vm.model.name))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"registered_at","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Дата регистрации","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.model.registered_at),callback:function ($$v) {_vm.$set(_vm.model, "registered_at", $$v)},expression:"model.registered_at"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.model.registered_at),callback:function ($$v) {_vm.$set(_vm.model, "registered_at", $$v)},expression:"model.registered_at"}})],1)]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center pb-3 px-3"},[_c('v-btn',{attrs:{"loading":!!_vm.saving,"disabled":!!_vm.saving || invalid,"rounded":"","color":"success"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_vm._v("Сохранить ")]),_c('v-btn',{attrs:{"disabled":!!_vm.saving,"loading":!!_vm.saving,"rounded":"","text":"","color":"error"},on:{"click":function($event){return _vm.cancel(reset)}}},[_vm._v("Отмена ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }