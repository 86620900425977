<template>
  <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
    <v-card class="advertisement-form-container">
      <v-card-title>
        {{model.id ? 'Изменить карточку клиента' : 'Добавить карточку клиента'}}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p>Клиент: {{model.name}}</p>
        <v-row>
          <v-col cols="12">
            <validation-provider v-slot="{errors}" name="registered_at" rules="required">
              <v-menu v-model="dateMenu" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="model.registered_at" label="Дата регистрации" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="model.registered_at" @input="dateMenu = false"></v-date-picker>
              </v-menu>
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center pb-3 px-3">
        <v-btn @click="handleSubmit(save)"
               :loading="!!saving"
               :disabled="!!saving || invalid"
               rounded color="success"
        >Сохранить
        </v-btn>
        <v-btn @click="cancel(reset)"
               :disabled="!!saving"
               :loading="!!saving"
               rounded text color="error"
        >Отмена
        </v-btn>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'customer-date-form',

    data() {
      return {
        dateMenu: false,
      }
    },

    computed: {
      ...mapState('Customer', ['saving', 'model']),
    },

    methods: {
      cancel(reset) {
        reset()
        this.$emit('customer:date-form:cancel')
      },

      save() {
        this.$store
            .dispatch('Customer/updateRegisterDate')
            .then(() => {
              this.$emit('customer:date-form:saved')
            })
      },
    },
  }
</script>
