<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col sm="8" md="4" lg="3">
        <img src="@/assets/welcome.png" alt="Logo" width="100%" class="mt-5">
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
        <v-col v-if="inited" sm="12" md="4" lg="3">
            <div class="text-h5 text-center">Авторизация</div>
            <validation-observer ref="observer" v-slot="{handleSubmit}">
                <form ref="loginForm">
                    <validation-provider v-slot="{errors}" name="email" rules="required">
                        <v-text-field v-model="form.email"
                                      :error-messages="errors"
                                      label="Email" required :disabled="!!authing"
                        ></v-text-field>
                    </validation-provider>
                    <validation-provider v-slot="{errors}" name="password" rules="required">
                        <v-text-field v-model="form.password"
                                      current-password
                                      :error-messages="errors"
                                      label="Пароль" required :disabled="!!authing"
                                      :type="showPassword ? 'text' : 'password'"
                                      @click:append="showPassword = !showPassword"
                                      append-icon="mdi-eye"
                                      @keydown.enter="handleSubmit(login)"
                        ></v-text-field>
                    </validation-provider>

                    <div class="text-center">
                      <v-btn :disabled="!!authing" :loading="!!authing"
                             @click="handleSubmit(login)"
                             color="primary" class="mt-2"
                      >Войти</v-btn>
                    </div>
                </form>
            </validation-observer>
        </v-col>
        <v-col v-else cols="12">
            <v-row align="center" justify="center">
                <v-progress-circular size="30" width="2" color="primary" indeterminate></v-progress-circular>
            </v-row>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
    import {mapState}   from 'vuex'
    import {AuthForm}   from '@/store/auth'
    import ut           from '@/utils'

    export default {
        name: 'login',

        data() {
            return {
                showPassword: false,
                form: {},
                inited: false,
                pl: 0,
            }
        },

        computed: {
            ...mapState(['drawer']),
            ...mapState('Auth', [
                'authing',
                'user',
            ]),
        },

        methods: {
            login() {
                this.$store.dispatch('Auth/login', this.form)
            }
        },

        created() {
            if (this.user.id) {
                this.$router.push({name: 'customers.list'})
            }
            this.$store.dispatch('Auth/loadCsrf').then(() => {
                this.inited = true
            })

            this.form = ut.deepClone(AuthForm)

            this.$eventBus.$on('auth:success', () => {
                this.$store.dispatch('load')
                this.$router.push({name: 'customers.list'})
            })
        },

        beforeDestroy() {
            this.$eventBus.$off('auth:success')
        }
    }
</script>
