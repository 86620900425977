<template>
    <v-layout align-center justify-center>
        <v-flex shrink class="text-center">
            <h1 class="title is-1">404</h1>
            <p>Not found</p>
        </v-flex>
    </v-layout>
</template>

<script>
  export default {
    name: 'not-found',
  }
</script>