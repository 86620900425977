import ut from '@/utils'

import {Resource} from 'vue-resource'

const Crm = {
  id: null,
  subscription_date: (new Date).toISOString().substring(0, 10),
  registration_date: (new Date).toISOString().substring(0, 10),
  uniq_id: '',
  series: '',
  customer_id: '',
  state: '',
}

const CrmResource = new Resource('crms{/id}', {}, {
  confirm: {
    method: 'POST',
    url: 'crms{/id}/confirm'
  },
  updateDate: {
    method: 'POST',
    url: 'crms/updateDate'
  },
})

export default {
  namespaced: true,

  state: {
    updating: false,
    saving: false,
    states: [
      {name: 'активный', id: 'active'},
      {name: 'неактивный', id:  'inactive'},
      {name: 'в ожидании', id:  'pending'},
    ],
  },

  mutations: {
    setModel(state, data) {
      state.model = {...ut.deepClone(Crm), ...data}
    },
  },

  actions: {
    confirmActivation({state, commit}, id) {
      if (!state.updating) {
        state.updating = CrmResource
                          .confirm({id}, {})
                          .finally(() => state.updating = false)
      }

      return state.fetching
    },

    remove({state, commit}, {id, pass}) {
      if (!state.updating) {
        state.updating = CrmResource
                          .delete({id}, {pass})
                          .finally(() => state.updating = false)
      }

      return state.updating
    },

    save({state, commit}) {
      state.saving = true
      let promise
      if (state.model.id) {
        promise = CrmResource.update({id: state.model.id}, state.model)
      } else {
        promise = CrmResource.save(state.model)
      }

      return promise
                .then(response => {
                  commit('setModel', response.body)
                })
                .finally(() => state.saving = false)
    },

    updateRegisterDate({state, commit}) {
      state.saving = true

      return CrmResource.updateDate({id: state.model.id}, {date: state.model.registration_date})
        .then(response => {
          commit('setModel', response.body)
        })
        .finally(() => state.saving = false)
    },
  }
}
