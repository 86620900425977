import Vue from 'vue'
import ut from '@/utils'

import {Resource} from 'vue-resource'

const Customer = {
  id: null,
  name: '',
  phone: '',
  address: '',
  balance: 0,
  tin: null,
  pinfl: null,
  region_id: null,
  area_id: null,
  state: null,
  registered_at: (new Date).toISOString().substr(0, 10),
}

const CustomerResource = new Resource('customers{/id}', {}, {
  import: {
    method: 'POST',
    url: 'customers/import'
  },
  updateDate: {
    method: 'POST',
    url: 'customers/updateDate'
  },
})

export default {
  namespaced: true,

  state: {
    data: null,
    fetching: false,
    importing: false,
    model: ut.deepClone(Customer),
    deleting: {},
    saving: false,
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(Customer), ...data}
    },
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = CustomerResource
          .get(ut.objFilter(filters))
          .then((response) => {
            commit('setItems', response.body)
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    show({state, commit}, id) {
      if (!state.fetching) {
        commit('setModel', {})
        state.fetching = CustomerResource.get({id})
             .then((response) => {
               commit('setModel', response.body)
             })
             .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    save({state, commit}) {
      state.saving = true
      let promise
      if (state.model.id) {
        promise = CustomerResource.update({id: state.model.id}, state.model)
      } else {
        promise = CustomerResource.save(state.model)
      }

      return promise
        .then(response => {
          commit('setModel', response.body)
        })
        .catch(response => Vue.$eventBus.$emit('notification:error', response.body.message))
        .finally(() => state.saving = false)
    },

    updateRegisterDate({state, commit}) {
      state.saving = true

      return CustomerResource.updateDate({id: state.model.id}, {date: state.model.registered_at})
                .then(response => {
                  commit('setModel', response.body)
                })
                .finally(() => state.saving = false)
    },

    import({state, commit}, data) {
      state.importing = true
      return CustomerResource
                .import(data)
                .finally(() => state.importing = false)
    },

    remove({state, dispatch}, id) {
      debugger
      Vue.set(state.deleting, id, true)
      return CustomerResource
        .delete({id})
        .then(() => dispatch('fetch'))
        .finally(() => Vue.set(state.deleting, id, false))
    },
  },
}
