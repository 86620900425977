<template>
  <v-card>
    <v-card-title>Выберите файл для загрузки</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-file-input
        @change="updateFormData"
        counter autofocus clearable
        label="File" hint="*.xls file"
        truncate-length="34"
      ></v-file-input>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="primary" :disabled="!formData || loading" :loading="loading" @click="upload">Импорт</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="error" :disabled="loading" @click="close">Закрыть</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'uploader',

    props: {
      storeNs: {
        required: true,
        type: String,
      },
      name: {
        type: String,
        default: 'file',
      },
      loading: Boolean,
    },

    data() {
      return {
        formData: null
      }
    },

    methods: {
      updateFormData(file) {
        if (file) {
          this.formData = new FormData()
          this.formData.append(this.name, file)
        } else {
          this.formData = null
        }
      },

      upload() {
        this.$store.dispatch(this.storeNs, this.formData)
                   .then(() => {
                     this.$eventBus.$emit('notification:success', 'Импортировано')
                   })
                   .catch(() => {
                     this.$eventBus.$emit('notification:error', 'Ошибка при импорте')
                   })
                   .finally(() => {
                     this.$eventBus.$emit('upload:finished')
                     this.close()
                   })
      },

      close() {
        this.formData = null
        this.$emit('dialog:close')
      }
    },

    beforeDestroy() {
      this.close()
    }
  }
</script>
