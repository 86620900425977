<template>
  <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
    <v-card class="advertisement-form-container">
      <v-card-title>
        {{model.id ? 'Изменить карточку клиента' : 'Добавить карточку клиента'}}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <validation-provider v-slot="{errors}" name="name" rules="required|max:256">
              <v-text-field v-model="model.name"
                            required :error-messages="errors"
                            label="Название организации"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider v-slot="{errors}" name="tin" :rules="model.pinfl ? '' : 'required|integer|max:14'">
              <v-text-field-simplemask
                v-model="model.tin"
                label="ИНН клиента"
                :options="{
                  inputMask: '### ### ###',
                  outputMask: '#########',
                  empty: null,
                  numeric: true,
                }"
              ></v-text-field-simplemask>
            </validation-provider>
          </v-col>

          <v-col cols="12">
            <validation-provider v-slot="{errors}" name="pinfl" :rules="model.tin ? '' : 'required|integer|max:9'">
              <v-text-field-simplemask
                v-model="model.pinfl"
                label="ПИНФЛ клиента"
                :options="{
                  inputMask: '# ###### ##### ##',
                  outputMask: '##############',
                  empty: null,
                  numeric: true,
                }"
              ></v-text-field-simplemask>
            </validation-provider>
          </v-col>

          <v-col cols="12">
            <validation-provider v-slot="{errors}" name="registered_at" rules="required">
              <v-menu v-model="dateMenu" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="computedDate" label="Дата регистрации" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="model.registered_at" @input="dateMenu = false"></v-date-picker>
              </v-menu>

            </validation-provider>
          </v-col>

          <v-col cols="12">
            <validation-provider v-slot="{errors}" name="region" rules="required">
              <v-select v-model="model.region_id"
                        :items="regions"
                        item-value="id" item-text="name"
                        required :error-messages="errors"
                        label="Область"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12" v-if="model.region_id">
            <validation-provider v-slot="{errors}" name="area" rules="required">
              <v-select v-model="model.area_id"
                        :items="areas"
                        item-text="name" item-value="id"
                        required :error-messages="errors"
                        label="Район"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12">
            <validation-provider v-slot="{errors}" name="address" rules="required">
              <v-text-field v-model="model.address"
                            required :error-messages="errors"
                            label="Адрес"
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12">
            <validation-provider v-slot="{errors}" name="pinfl" rules="required|integer">
              <v-text-field-simplemask
                v-model="model.phone"
                label="Телефон"
                :options="{
                  inputMask: '+### (##) ###-##-##',
                  outputMask: '############',
                  empty: null,
                  numeric: true,
                }"
              ></v-text-field-simplemask>
            </validation-provider>
          </v-col>

          <v-col cols="12">
            <validation-provider v-slot="{errors}" name="balance" rules="required|integer">
              <v-text-field v-model="model.balance"
                            :mask="balanceMask"
                            :error-messages="errors"
                            label="Баланс"
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12">
            <validation-provider v-slot="{errors}" name="state" rules="required">
              <v-select v-model="model.state"
                        :items="statuses"
                        item-text="name" item-value="id"
                        required :error-messages="errors"
                        label="Статус"
              ></v-select>
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center pb-3 px-3">
        <v-btn @click="handleSubmit(save)"
               :loading="!!saving"
               :disabled="!!saving || invalid"
               rounded color="success"
        >Сохранить
        </v-btn>
        <v-btn @click="cancel(reset)"
               :disabled="!!saving"
               :loading="!!saving"
               rounded text color="error"
        >Отмена
        </v-btn>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'customer-form',

    data() {
      return {
        phoneMask: '/^[0-9]{2} [0-9]{3}-[0-9]{2}-[0-9]{2}$/',
        balanceMask: '/^[0-9]{0-10}$/',
        tinMask: '/^[0-9]{9}$/',
        pinflMask: '/^[0-9]{14}$/',
        dateMenu: false,
      }
    },

    computed: {
      ...mapState('Customer', ['saving', 'model']),
      ...mapState({
        areas: state => (state.areas || []).filter(a => {
          return state.Customer.model && state.Customer.model.region_id && (+a.region_id === +state.Customer.model.region_id)
        }),
        regions: state => state.regions || [],
        statuses: state => state.statuses
      }),
      computedDate() {
        return this.model.registered_at
      }
    },

    methods: {
      cancel(reset) {
        reset()
        this.$emit('customer:form:cancel')
      },

      save() {
        this.$store
            .dispatch('Customer/save')
            .then(() => {
              this.$emit('customer:form:saved')
            })
      },
    },
  }
</script>
