var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',{staticClass:"payment-form-container"},[_c('v-card-title',[_vm._v("Добавить платеж")]),_c('v-divider'),_c('v-card-text',[_c('v-layout',{staticClass:"mx-4",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":"tin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"ИНН/ПИНФЛ клиента"},model:{value:(_vm.model.tax_id),callback:function ($$v) {_vm.$set(_vm.model, "tax_id", $$v)},expression:"model.tax_id"}})]}}],null,true)})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":"doc_num","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Номер документа"},model:{value:(_vm.model.doc_num),callback:function ($$v) {_vm.$set(_vm.model, "doc_num", $$v)},expression:"model.doc_num"}})]}}],null,true)})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Описание"},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})]}}],null,true)})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":"amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Сумма"},model:{value:(_vm.model.amount),callback:function ($$v) {_vm.$set(_vm.model, "amount", $$v)},expression:"model.amount"}})]}}],null,true)})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":"amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"datemenu",attrs:{"close-on-content-click":false,"return-value":_vm.model.payment_date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.model, "payment_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.model, "payment_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Дата платежа","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.pdate),callback:function ($$v) {_vm.pdate=$$v},expression:"pdate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.datemenu),callback:function ($$v) {_vm.datemenu=$$v},expression:"datemenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.model.payment_date),callback:function ($$v) {_vm.$set(_vm.model, "payment_date", $$v)},expression:"model.payment_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.datemenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.datemenu.save(_vm.model.payment_date)}}},[_vm._v("OK")])],1)],1)]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center pb-3 px-3"},[_c('v-btn',{attrs:{"loading":!!_vm.saving,"disabled":!!_vm.saving || invalid,"rounded":"","color":"success"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_vm._v("Save ")]),_c('v-btn',{attrs:{"disabled":!!_vm.saving,"loading":!!_vm.saving,"rounded":"","text":"","color":"error"},on:{"click":function($event){return _vm.cancel(reset)}}},[_vm._v("Cancel ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }