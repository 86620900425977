import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

import colors from 'vuetify/lib/util/colors'

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.indigo.darken1,
        error: colors.red.darken3,
      },
      dark: {
        primary: colors.indigo.darken1,
        error: colors.red.darken3,
      },
    },
  },
});
