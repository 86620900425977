<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <span style="vertical-align:middle;" class="display-1">Отчеты</span>
      </v-col>
      <v-col cols="4">
        <v-card outlined color="red darken-3">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1 white--text">
                Задолжники
              </v-list-item-title>
              <v-list-item-subtitle class="white--text">скачать список задолников на дату {{today}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="80">
              <v-icon x-large>mdi-file-download</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions>
            <v-btn @click="$router.push({name: 'customers.list', query: {balance: '-1'}})" color="white" rounded small text depressed>перейти</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="downloadDebtAccounts" color="white" rounded small text depressed>Скачать</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card outlined color="warning lighten-2">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1 white--text">
                ККМы
              </v-list-item-title>
              <v-list-item-subtitle class="white--text">скачать список ККМ для включения</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="80">
              <v-icon x-large>mdi-file-download</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions>
            <v-btn @click="$router.push({name: 'customers.list', query: {crm_state: 'pending'}})" color="white" rounded small text depressed>перейти</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="downloadPendingCrms" color="white" rounded small text depressed>скачать</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card outlined color="teal lighten-3">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1 white--text">
                ККМы
              </v-list-item-title>
              <v-list-item-subtitle class="white--text">скачать список ККМ</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="80">
              <v-icon x-large>mdi-file-download</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions>
            <v-btn @click="$router.push({name: 'customers.list', query: {crm_state: 'inactive'}})" color="white" rounded small text depressed>перейти</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="downloadCrms" color="white" rounded small text depressed>Скачать</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import {mapState} from 'vuex'

  export default {
    name: 'report',
    data() {
      return {
        today: moment().format('DD-MM-YYYY'),
        downloadingDebts: false,
      }
    },

    computed: {
      ...mapState(['organization_id'])
    },

    methods: {
      downloadDebtAccounts() {
        window.location.href = this.$http.options.root + '/report/accounts/indebt?organization_id=' + this.organization_id
      },
      downloadCrms() {
        window.location.href = this.$http.options.root + '/report/crms/ok?organization_id=' + this.organization_id
      },
      downloadPendingCrms() {
        window.location.href = this.$http.options.root + '/report/crms/pending?organization_id=' + this.organization_id
      },
    }
  }
</script>
