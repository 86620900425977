import Vue from 'vue'
import ut from '@/utils'

import {Resource} from 'vue-resource'

export const Payment = {
  id: null,
  customer_id: null,
  description: '',
  doc_num: '',
  tax_id: null,
  amount: 0,
  payment_date: new Date().toISOString().substr(0, 10),
}

const PaymentResource = new Resource('payments{/id}', {}, {
  import: {
    method: 'POST',
    url: 'payments/import'
  },
  correct: {
    method: 'POST',
    url: 'payments/correct'
  },
})

const PaymentDetailResource = new Resource('paymentDetails{/id}')

export default {
  namespaced: true,

  state: {
    data: null,
    fetching: false,
    saving: false,
    importing: false,
    model: ut.deepClone(Payment),
    deleting: {},

    fetchingDetails: false,
    details: null,
  },

  mutations: {
    setDetails(state, data) {
      state.details = data
    },
    setItems(state, data) {
      state.data = data
    },
    setModel(state) {
      state.model = ut.deepClone(Payment)
    }
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = PaymentResource
                            .get(ut.objFilter(filters))
                            .then((response) => {
                              commit('setItems', response.body)
                            })
                            .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    fetchDetails({state, commit}, filters = {}) {
      if (!state.fetchingDetails) {
        commit('setDetails', null)
        state.fetchingDetails = PaymentDetailResource
                            .get(ut.objFilter(filters))
                            .then((response) => {
                              commit('setDetails', response.body)
                            })
                            .finally(() => state.fetchingDetails = false)
      }

      return state.fetchingDetails
    },

    clearDetails({state, commit}) {
        commit('setDetails', null)
    },

    save({state, commit}) {
      state.saving = true
      let promise
      if (state.model.id) {
        promise = PaymentResource.update({id: state.model.id}, state.model)
      } else {
        promise = PaymentResource.save(state.model)
      }

      return promise
        .then(response => {
          commit('setModel', response.body)
        })
        .finally(() => state.saving = false)
    },

    correct({state, commit}, data) {
      state.saving = true
      return PaymentResource.correct({}, data)
                .finally(() => state.saving = false)
    },

    import({state, commit}, data) {
      state.importing = true
      return PaymentResource
                .import(data)
                .finally(() => state.importing = false)
    },

    remove({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return PaymentResource
              .delete({id})
              .catch(response => Vue.$eventBus.$emit('notification:error', response.body.message))
              .finally(() => Vue.set(state.deleting, id, false))
    },
    removeDetail({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return PaymentDetailResource
              .delete({id})
              .catch(response => Vue.$eventBus.$emit('notification:error', response.body.message))
              .finally(() => Vue.set(state.deleting, id, false))
    },
  },
}
