import Vue from 'vue'
import VueRouter from 'vue-router'
import store       from '@/store'

import NotFound from '@/pages/404'
import login from '@/modules/auth/login'

import Report from '@/pages/report'
import CustomerModule from '@/modules/customers/list'
import PaymentModule from '@/modules/payments/list'
import SmsModule from '@/modules/sms/list'

const profile = () => import(/* webpackChunkName: 'app-profile' */ '@/modules/auth/profile')

const strats = Vue.config.optionMergeStrategies
strats.beforeRouteEnter = strats.beforeRouteLeave = strats.beforeRouteUpdate = strats.created
Vue.use(VueRouter)

const routes = [
  {
    path: '/profile',
    name: 'profile',
    component: profile,
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      guest: true,
    },
  },
  {
    path: '/',
    name: 'report',
    component: Report,
  },
  {
    path: '/customers',
    name: 'customers.list',
    component: CustomerModule,
    props: true,
  },
  {
    path: '/payments',
    name: 'payments.list',
    component: PaymentModule,
  },
  {
    path: '/sms',
    name: 'sms.list',
    component: SmsModule,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/pages/about.vue')
  },
  {
    path: '*',
    name: 'not.found',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (store.state.inited) {
    if (!store.state.Auth.user.id && !to.meta.guest) {
      next({name: 'login'})
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
