import moment from 'moment'

export const filters = {
  dateTime(date) {
    return date ? moment(date).locale('ru').format('DD.MM.YYYY, HH:mm') : ''
  },

  date(date) {
    return date ? moment(date).locale('ru').format('DD.MM.YYYY') : ''
  },

  money(x = 0, currency = true) {
    return `${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}${currency ? ' сум' : ''}`;
  },

  phone(rawPhone) {
    return (rawPhone || '').toString()
            .replace(/[^0-9]/g, '')
            .replace(/(\d{2})(\d{3})(\d{2})(\d{2})/g, "+998 ($1) $2-$3-$4")
          || 'Not set'
  },

  userName(user = {}) {
    return user.full_name || user.login
  },

  shorten(str) {
    return str.length > 50 ? str.toString().substr(0, 50) + '...' : str
  },
}

const filterInstaller = {
  install(Vue) {
    for (let key in filters) {
      Vue.filter(key, filters[key])
    }
  }
}

export {filterInstaller}
