var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',{staticClass:"advertisement-form-container"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.model.id ? 'Изменить карточку ККМ' : 'Добавить карточку ККМ')+" ")]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"series","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Фискал"},model:{value:(_vm.model.series),callback:function ($$v) {_vm.$set(_vm.model, "series", $$v)},expression:"model.series"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"uniq_id","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Серия"},model:{value:(_vm.model.uniq_id),callback:function ($$v) {_vm.$set(_vm.model, "uniq_id", $$v)},expression:"model.uniq_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"registration_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Дата регистрации","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.model.registration_date),callback:function ($$v) {_vm.$set(_vm.model, "registration_date", $$v)},expression:"model.registration_date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.dateMenu1),callback:function ($$v) {_vm.dateMenu1=$$v},expression:"dateMenu1"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateMenu1 = false}},model:{value:(_vm.model.registration_date),callback:function ($$v) {_vm.$set(_vm.model, "registration_date", $$v)},expression:"model.registration_date"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"subscription_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Оплачен до","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.model.subscription_date),callback:function ($$v) {_vm.$set(_vm.model, "subscription_date", $$v)},expression:"model.subscription_date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.dateMenu2),callback:function ($$v) {_vm.dateMenu2=$$v},expression:"dateMenu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateMenu2 = false}},model:{value:(_vm.model.subscription_date),callback:function ($$v) {_vm.$set(_vm.model, "subscription_date", $$v)},expression:"model.subscription_date"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.states,"item-text":"name","item-value":"id","required":"","error-messages":errors,"label":"Статус"},model:{value:(_vm.model.state),callback:function ($$v) {_vm.$set(_vm.model, "state", $$v)},expression:"model.state"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center pb-3 px-3"},[_c('v-btn',{attrs:{"loading":!!_vm.saving,"disabled":!!_vm.saving || invalid,"rounded":"","color":"success"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_vm._v("Save ")]),_c('v-btn',{attrs:{"disabled":!!_vm.saving,"loading":!!_vm.saving,"rounded":"","text":"","color":"error"},on:{"click":function($event){return _vm.cancel(reset)}}},[_vm._v("Cancel ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }