<template>
  <v-app id="inspire">
    <v-navigation-drawer :mini-variant="miniDrawer" app clipped v-if="user.id">
      <v-list dense>
        <v-list-item v-for="(item, index) in links" :key="index" link :to="{name: item.routeName}">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar dark app dense clipped-left color="indigo darken-1">
      <v-app-bar-nav-icon @click="miniDrawer = !miniDrawer" v-if="user.id">
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon>
        <v-icon>mdi-account-supervisor-circle</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-title class="pl-0">SmartBank CRM
       <span v-if="user.organization_id">- {{(user.organization || {}).name}}</span>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-menu v-if="user.id">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="indigo darken-1" depressed>
            <v-icon left>mdi-account</v-icon> {{user.name}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item :to="{name: 'profile'}">
            <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
            <v-list-item-title>Профиль</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon><v-icon>mdi-exit-to-app</v-icon></v-list-item-icon>
            <v-list-item-title>Выйти</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <template v-if="inited">
          <router-view></router-view>
      </template>

      <div v-else style="text-align: center;" class="mt-5">
        <v-progress-circular width="2" indeterminate></v-progress-circular>
      </div>
    </v-main>
    <v-snackbar v-model="notification" :timeout="2000" :color="notificationError ? 'error' : 'success'" top right>
      {{ notificationText }}
      <template v-slot:action="{ attrs }">
        <v-btn text icon v-bind="attrs" @click="notification = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
  import {mapState, mapMutations} from 'vuex'

  export default {
    name: 'App',

    data() {
      return {
        title: '',
        links: [
          {icon: 'mdi-account-group', title: 'Клиенты', routeName: 'customers.list'},
          {icon: 'mdi-cash', title: 'Платежи', routeName: 'payments.list'},
          {icon: 'mdi-file-excel', title: 'Отчеты', routeName: 'report'},
          // {icon: 'mdi-send', title: 'SMS', routeName: 'sms.list'},
        ],

        notificationText: '',
        notification: false,
        notificationError: false,
      }
    },

    computed: {
      ...mapState(['inited']),
      ...mapState('Auth', ['user']),

      miniDrawer: {
        get() {
          return this.$store.state.miniDrawer
        },
        set() {
          this.$store.commit('resizeDrawer')
        }
      }
    },

    methods: {
      logout() {
        this.$store
          .dispatch('Auth/logout')
          .then(() => {
            this.$router.push({name: 'login'})
          })
          .catch(() => {})
      },
    },

    created() {
      this.$store.dispatch('load')

      this.$eventBus.$on('setTitle', (val) => this.title = val)
      this.$eventBus.$on('notification:success', (val) => {
        this.notificationText = val
        this.notification = true
        this.notificationError = false
      })
      this.$eventBus.$on('notification:error', (val) => {
        this.notificationText = val
        this.notification = true
        this.notificationError = true
      })
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => vm.$auth.check())
    },

    beforeRouteUpdate() {
      this.$auth.check()
    },
  };
</script>
