<template>
  <div>
    <v-subheader class="px-0">Детализация по балансу</v-subheader>
    <v-data-table
      :headers="headers"
      :items="detailsList"
      :loading="!!fetchingDetails"
      disable-items-per-page hide-default-footer
      :items-per-page="-1"
      :server-items-length="detailsList.length"
    >
      <template #item="{item, index}">

        <tr :class="{'green lighten-5': item.payable_type === topup, 'red lighten-5': item.payable_type === charge}">
          <td class="text-center">{{index + 1}}</td>
          <td class="text-center" :title="item.date">
            <span v-if="item.payable_type === topup">{{item.date|date}}</span>
            <span v-else>{{item.description}}</span>
          </td>
          <td v-if="item.payable_type === topup" class="text-left">
            Пополнение: {{item.payable.name}}
            <br>
            {{item.description.data_provodki ? 'Дата проводки:' + item.description.data_provodki : ''}}
            <br>
            {{item.description.nomer_dokumenta ? 'Номер документа:' + item.description.nomer_dokumenta : ''}}
          </td>
          <td v-if="item.payable_type === charge" class="text-left">Снятие: за ККМ {{item.payable.series}} / {{item.payable.uniq_id}}
            </td>
          <td>{{item.amount | money}}</td>
          <td>
            <v-btn v-if="item.payable_type === charge"
                   @click="removePaymentDetail(item.id)"
                   :loading="deleting[item.id]"
                   :disabled="deleting[item.id]"
                   color="error" small text
            >Удалить</v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'
  const {mapState, mapActions} = createNamespacedHelpers('Payment')

  export default {
    name: 'payment-details-list',

    props: {
      customerId: {
        required: true,
      }
    },

    data() {
      return {
        topup: 'App\\Models\\Customer',
        charge: 'App\\Models\\Crm'
      }
    },

    computed: {
      ...mapState(['fetchingDetails', 'details', 'deleting']),

      detailsList() {
        return this.details || []
      },

      headers() {
        return [
          {text: '#', align: 'center', width: '60'},
          {text: 'Дата', align: 'center'},
          {text: 'Причина', align: 'left'},
          {text: 'Сумма', align: 'left'},
          {text: 'Действие', align: 'left'},
        ]
      },
    },

    methods: {
      ...mapActions(['fetchDetails', 'clearDetails', 'removeDetail']),

      removePaymentDetail(id) {
        this.removeDetail(id)
          .then(() => {
            this.fetchDetails({customer_id: this.customerId})
          })
      }
    },

    watch: {
      customerId(newVal, oldVal) {
        if (newVal && newVal !== oldVal)
          this.fetchDetails({customer_id: this.customerId})
      },
    },

    created() {

    },

    beforeDestroy() {
      this.clearDetails()
    }
  }
</script>
