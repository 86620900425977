<template>
  <v-container fluid>
    в разработке
  </v-container>
</template>

<script>
  export default {
    name: 'sms-list',
  }
</script>
